import React, { useState, useEffect, useRef } from "react"
import css from "@styled-system/css"

import mapMarker from "../../../../static/marker.png"

function MapContainer({ branches, clickActiveBranch, center, zoom, selectedCountry, selectedProvince }) {
  console.log("branches", selectedCountry, selectedProvince)

  const [googleMap, setGoogleMap] = useState(undefined)
  const [geocoder, setGeocoder] = useState(undefined)

  const prevMarkersRef = useRef([]);

  useEffect(() => {
    const googleMapScript = document.createElement("script")
    googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDaEsTKlfNhSHY4zeV7BOmINxc7TDvoFDI&libraries=places,geometry`
    window.document.body.appendChild(googleMapScript)

    googleMapScript.addEventListener("load", () => {
      let map = createGoogleMap()
      setGoogleMap(map)
      let geocoderInstance = new window.google.maps.Geocoder();
      setGeocoder(geocoderInstance)
    })
  }, [])

  useEffect(() => {
    if (geocoder && googleMap) {
      if (selectedCountry && selectedCountry.value) {
        // Country selected, Province selected
        if (selectedProvince && selectedProvince.value && selectedProvince.value !== 'All') {
          let addressValue = selectedProvince.value;
          if (selectedProvince.value === 'Yukon') {
            addressValue = 'Yukon, Canada'
          }

          if (selectedProvince.value === 'Quebec') {
            addressValue = 'Canada Quebec Province'
          }

          if (selectedProvince.value === 'Georgia') {
            addressValue = 'USA Georgia State'
          }
          if (selectedProvince.value === 'Washington') {
            addressValue = 'USA Washington State'
          }

          if (selectedProvince.value === 'New York') {
            addressValue = 'USA New York State'
          }

          geocoder.geocode({ 'address': `${addressValue}` }, function (results, status) {
            if (status == 'OK') {
              googleMap.setCenter(results[0].geometry.location);
              googleMap.fitBounds(results[0].geometry.viewport);
              if (selectedProvince.value === 'Northwest Territories') {
                let zoomLevel = googleMap.getZoom();

                googleMap.setZoom(zoomLevel + 1)
              }
              if (selectedProvince.value === 'Alaska') {
                let zoomLevel = googleMap.getZoom();

                googleMap.setZoom(zoomLevel + 1)
              }
              if (selectedProvince.value === 'Nunavut') {
                let zoomLevel = googleMap.getZoom();

                googleMap.setZoom(zoomLevel + 1)
              }
            } else {
              alert('Geocode was not successful for the following reason: ' + status);
            }
          });
        }
        // Province not selected, Country selected
        else if (selectedCountry.value && selectedCountry.value !== 'All') {
          geocoder.geocode({ 'address': selectedCountry.value }, function (results, status) {
            if (status == 'OK') {
              googleMap.setCenter(results[0].geometry.location);
              googleMap.fitBounds(results[0].geometry.viewport);
              if (selectedCountry.value === 'Canada') {
                let zoomLevel = googleMap.getZoom();

                googleMap.setZoom(zoomLevel + 1)
              }
            } else {
              alert('Geocode was not successful for the following reason: ' + status);
            }
          });
        }
        // Province not selected, Country not selected
        else {
          geocoder.geocode({ 'address': 'North America' }, function (results, status) {
            if (status == 'OK') {
              googleMap.setCenter(results[0].geometry.location);
              googleMap.fitBounds(results[0].geometry.viewport);
            } else {
              alert('Geocode was not successful for the following reason: ' + status);
            }
          });
        }
      }

    }
  }, [selectedCountry, selectedProvince, googleMap, geocoder])

  useEffect(() => {
    if (googleMap) {
      clearMarkers(prevMarkersRef.current);
      prevMarkersRef.current = []

      branches.forEach(branch => {
        if (branch.coordinates) {
          createMarker(
            branch.coordinates.latitude,
            branch.coordinates.longitude,
            branch
          )
        }
      })
    }
  }, [googleMap, branches])

  useEffect(() => {
    if (googleMap && zoom) {
      googleMap.setZoom(zoom)
    }
  }, [zoom])

  // useEffect(() => {
  //   if (center && googleMap) {
  //     const centerLatLng = new window.google.maps.LatLng(
  //       center.latitude,
  //       center.longitude
  //     )
  //     googleMap.panTo(centerLatLng)
  //   }
  // }, [center])

  const createMarker = function (lat, lng, branch) {

    let marker = new window.google.maps.Marker({
      position: { lat: lat, lng: lng },
      color: "#FCD06B",
      map: googleMap,
      icon: {
        url: mapMarker,
      },
    })

    window.google.maps.event.addListener(marker, "click", () => {
      const centerLatLng = new window.google.maps.LatLng(lat, lng)
      googleMap.panTo(centerLatLng)
      // googleMap.setZoom(14)
      clickActiveBranch(branch)
    })

    prevMarkersRef.current.push(marker);
  }

  const createGoogleMap = function () {
    return new window.google.maps.Map(document.getElementById("map"), {
      zoom: 3,
      center: {
        lat: center ? center.latitude : 0,
        lng: center ? center.longitude : 0,
      },
      disableDefaultUI: true,
      zoomControl: true,
      zoomControlOptions: {
        position: window.google.maps.ControlPosition.LEFT_TOP,
      },
      styles: [
        {
          elementType: "geometry",
          stylers: [
            {
              color: "#f5f5f5",
            },
          ],
        },
        {
          elementType: "labels.icon",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#616161",
            },
          ],
        },
        {
          elementType: "labels.text.stroke",
          stylers: [
            {
              color: "#f5f5f5",
            },
          ],
        },
        {
          featureType: "administrative.land_parcel",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#bdbdbd",
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "geometry",
          stylers: [
            {
              color: "#eeeeee",
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#757575",
            },
          ],
        },
        {
          featureType: "poi.park",
          elementType: "geometry",
          stylers: [
            {
              color: "#e5e5e5",
            },
          ],
        },
        {
          featureType: "poi.park",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#9e9e9e",
            },
          ],
        },
        {
          featureType: "road",
          elementType: "geometry",
          stylers: [
            {
              color: "#ffffff",
            },
          ],
        },
        {
          featureType: "road.arterial",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#757575",
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "geometry",
          stylers: [
            {
              color: "#dadada",
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#616161",
            },
          ],
        },
        {
          featureType: "road.local",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#9e9e9e",
            },
          ],
        },
        {
          featureType: "transit.line",
          elementType: "geometry",
          stylers: [
            {
              color: "#e5e5e5",
            },
          ],
        },
        {
          featureType: "transit.station",
          elementType: "geometry",
          stylers: [
            {
              color: "#eeeeee",
            },
          ],
        },
        {
          featureType: "water",
          elementType: "geometry",
          stylers: [
            {
              color: "#c9c9c9",
            },
          ],
        },
        {
          featureType: "water",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#9e9e9e",
            },
          ],
        },
      ],
    })
  }

  function clearMarkers(markers) {
    for (let m of markers) {
      m.setMap(null);
    }
  }

  return (
    <>
      <div
        id="map"
        css={css({
          width: "100%",
          minHeight: [0, 600],
          height: ["100vw", null, "75vh"],
        })}
      />
    </>
  )
}

export default MapContainer
