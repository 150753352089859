export const COUNTRY_ORDERS = {
  'Canada': 1,
  'USA': 2,
  'Bermuda': 3,
  'India': 4,
  'Kuwait': 5,
  'Saudi Arabia': 6,
  'United Arab Emirates': 7,
}

export const COUNTRY_OPTIONS = [
  {
    value: 'All',
    label: 'All'
  },
  {
    value: 'Canada',
    label: 'Canada'
  },
  {
    value: 'USA',
    label: 'USA'
  },
  {
    value: 'Bermuda',
    label: 'Bermuda'
  },
  {
    value: 'India',
    label: 'India'
  },
  {
    value: 'Kuwait',
    label: 'Kuwait'
  },
  {
    value: 'Saudi Arabia',
    label: 'Saudi Arabia'
  },
  {
    value: 'United Arab Emirates',
    label: 'United Arab Emirates'
  },
];

export const STATE_PROVINCE_OPTIONS = [
  {
    value: 'All',
    label: 'All',
    category: 'All'
  },
  {
    value: 'Alberta',
    label: 'Alberta',
    category: 'Canada'
  },
  {
    value: 'British Columbia',
    label: 'British Columbia',
    category: 'Canada'
  },
  {
    value: 'Manitoba',
    label: 'Manitoba',
    category: 'Canada'
  },
  {
    value: 'New Brunswick',
    label: 'New Brunswick',
    category: 'Canada'
  },
  {
    value: 'Newfoundland and Labrador',
    label: 'Newfoundland and Labrador',
    category: 'Canada'
  },
  {
    value: 'Northwest Territories',
    label: 'Northwest Territories',
    category: 'Canada'
  },
  {
    value: 'Nova Scotia',
    label: 'Nova Scotia',
    category: 'Canada'
  },
  {
    value: 'Nunavut',
    label: 'Nunavut',
    category: 'Canada'
  },
  {
    value: 'Ontario',
    label: 'Ontario',
    category: 'Canada'
  },
  {
    value: 'Prince Edward Island',
    label: 'Prince Edward Island',
    category: 'Canada'
  },
  {
    value: 'Quebec',
    label: 'Quebec',
    category: 'Canada'
  },
  {
    value: 'Saskatchewan',
    label: 'Saskatchewan',
    category: 'Canada'
  },
  {
    value: 'Yukon',
    label: 'Yukon',
    category: 'Canada'
  },

  {
    value: 'Alabama',
    label: 'Alabama',
    category: 'USA'
  },
  {
    value: 'Alaska',
    label: 'Alaska',
    category: 'USA'
  },
  {
    value: 'Arizona',
    label: 'Arizona',
    category: 'USA'
  },
  {
    value: 'Arkansas',
    label: 'Arkansas',
    category: 'USA'
  },
  {
    value: 'California',
    label: 'California',
    category: 'USA'
  },
  {
    value: 'Colorado',
    label: 'Colorado',
    category: 'USA'
  },
  {
    value: 'Connecticut',
    label: 'Connecticut',
    category: 'USA'
  },
  {
    value: 'Delaware',
    label: 'Delaware',
    category: 'USA'
  },
  {
    value: 'District of Columbia',
    label: 'District of Columbia',
    category: 'USA'
  },
  {
    value: 'Florida',
    label: 'Florida',
    category: 'USA'
  },
  {
    value: 'Georgia',
    label: 'Georgia',
    category: 'USA'
  },
  {
    value: 'Hawaii',
    label: 'Hawaii',
    category: 'USA'
  },
  {
    value: 'Idaho',
    label: 'Idaho',
    category: 'USA'
  },
  {
    value: 'Illinois',
    label: 'Illinois',
    category: 'USA'
  },
  {
    value: 'Indiana',
    label: 'Indiana',
    category: 'USA'
  },
  {
    value: 'Iowa',
    label: 'Iowa',
    category: 'USA'
  },
  {
    value: 'Kansas',
    label: 'Kansas',
    category: 'USA'
  },
  {
    value: 'Kentucky',
    label: 'Kentucky',
    category: 'USA'
  },
  {
    value: 'Louisiana',
    label: 'Louisiana',
    category: 'USA'
  },
  {
    value: 'Maine',
    label: 'Maine',
    category: 'USA'
  },
  {
    value: 'Maryland',
    label: 'Maryland',
    category: 'USA'
  },
  {
    value: 'Massachusetts',
    label: 'Massachusetts',
    category: 'USA'
  },
  {
    value: 'Michigan',
    label: 'Michigan',
    category: 'USA'
  },
  {
    value: 'Minnesota',
    label: 'Minnesota',
    category: 'USA'
  },
  {
    value: 'Mississippi',
    label: 'Mississippi',
    category: 'USA'
  },
  {
    value: 'Missouri',
    label: 'Missouri',
    category: 'USA'
  },
  {
    value: 'Montana',
    label: 'Montana',
    category: 'USA'
  },
  {
    value: 'Nebraska',
    label: 'Nebraska',
    category: 'USA'
  },
  {
    value: 'Nevada',
    label: 'Nevada',
    category: 'USA'
  },
  {
    value: 'New Hampshire',
    label: 'New Hampshire',
    category: 'USA'
  },
  {
    value: 'New Jersey',
    label: 'New Jersey',
    category: 'USA'
  },
  {
    value: 'New Mexico',
    label: 'New Mexico',
    category: 'USA'
  },
  {
    value: 'New York',
    label: 'New York',
    category: 'USA'
  },
  {
    value: 'North Carolina',
    label: 'North Carolina',
    category: 'USA'
  },
  {
    value: 'North Dakota',
    label: 'North Dakota',
    category: 'USA'
  },
  {
    value: 'Ohio',
    label: 'Ohio',
    category: 'USA'
  },
  {
    value: 'Oklahoma',
    label: 'Oklahoma',
    category: 'USA'
  },
  {
    value: 'Oregon',
    label: 'Oregon',
    category: 'USA'
  },
  {
    value: 'Pennsylvania',
    label: 'Pennsylvania',
    category: 'USA'
  },
  {
    value: 'Rhode Island',
    label: 'Rhode Island',
    category: 'USA'
  },
  {
    value: 'South Carolina',
    label: 'South Carolina',
    category: 'USA'
  },
  {
    value: 'South Dakota',
    label: 'South Dakota',
    category: 'USA'
  },
  {
    value: 'Tennessee',
    label: 'Tennessee',
    category: 'USA'
  },
  {
    value: 'Texas',
    label: 'Texas',
    category: 'USA'
  },
  {
    value: 'Utah',
    label: 'Utah',
    category: 'USA'
  },
  {
    value: 'Vermont',
    label: 'Vermont',
    category: 'USA'
  },
  {
    value: 'Virginia',
    label: 'Virginia',
    category: 'USA'
  },
  {
    value: 'Washington',
    label: 'Washington',
    category: 'USA'
  },
  {
    value: 'West Virginia',
    label: 'West Virginia',
    category: 'USA'
  },
  {
    value: 'Wisconsin',
    label: 'Wisconsin',
    category: 'USA'
  },
  {
    value: 'Wyoming',
    label: 'Wyoming',
    category: 'USA'
  },
]
