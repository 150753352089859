import React from "react"
import css from "@styled-system/css"

import { Flex, H6, Text } from "components"

const ContactField = ({ heading, text }) => {
  return (
    <Flex mb={1}>
      <H6
        children={heading}
        mr={16}
        css={css({
          minWidth: 100,
          width: [100],
        })}
      />
      <Text children={text} />
    </Flex>
  )
}

export default ContactField
